document.addEventListener('DOMContentLoaded', function() {
    // Find all elements with the class .jims-menu
    const menus = document.querySelectorAll('.jims-menu');

    // Loop through each menu element
    menus.forEach(function(menu) {
        // Get the unique ID of the menu
        const menuId = menu.id;
        console.log(menu);

        // Create unique trigger IDs based on the menu's ID
        const triggers = {
            'open': document.getElementById(`${menuId}-trigger-1`),
            'close': document.getElementById(`${menuId}-trigger-2`)
        };

        // Check if menu and triggers exist
        if (triggers.open && triggers.close) {
            // Add event listeners for opening and closing the menu
            triggers.open.addEventListener('click', function () {
                menu.classList.add('open');
            });

            triggers.close.addEventListener('click', function () {
                menu.classList.remove('open');
            });
        }

        const menuItemsWithChildren = menu.querySelectorAll(".jims-has-children");

        menuItemsWithChildren.forEach(function (menuItem) {
            menuItem.addEventListener("click", function (event) {
                const clickedAnchor = event.target.closest("a");
                const sideMenuAncestor = clickedAnchor ? clickedAnchor.closest(".jims-side-menu") : null;

                if (sideMenuAncestor) {
                    if (!clickedAnchor || clickedAnchor.classList.contains("jims-has-children-a")) {
                        event.stopPropagation();
                        this.classList.toggle("open");

                        if (clickedAnchor && clickedAnchor.classList.contains("jims-has-children-a")) {
                            event.preventDefault(); // Prevent default navigation behavior
                        }
                    }
                }
            });
        });

        // Check if the side menu is open
        const sideMenu = menu.classList.contains('jims-side-menu');
        if (sideMenu) {
            // Get all <a> elements with the data-menu-active attribute within this menu
            const activeLinks = menu.querySelectorAll('a[data-menu-active]');

            // Initialize a flag to track if the active link is found
            let activeLinkFound = false;

            // Use a for loop to iterate through the links
            for (let i = 0; i < activeLinks.length; i++) {
                const link = activeLinks[i];
                // Get the value of data-menu-active attribute
                const activeValue = link.getAttribute('data-menu-active');

                // Check if the value exists as a class in the body
                if (document.body.classList.contains(activeValue)) {
                    // Add the 'active-link' class to the current link
                    link.classList.add('active-link');

                    // Check if the link is a child of '.jims-has-children'
                    const parentHasChildren = link.closest('.jims-has-children');
                    if (parentHasChildren) {
                        // Add the 'open' class to the parent '.jims-has-children' element
                        parentHasChildren.classList.add('open');
                    }

                    // Set the flag to true to indicate that the active link has been found
                    activeLinkFound = true;

                    // Exit the loop
                    break;
                }
            }
        }
    });
});
